.extract {
  min-width: 600px;
  padding: 20px;

  @media (max-width: 800px) {
    min-width: 400px;
  }
}

.div-extracts {
  font-size: 21px;
  height: 100%;
  min-width: 600px;

  @media (max-width: 800px) {
    min-width: 400px;
  }
}

.error-message {
  height: 435px;
  position: relative;
  top: 215px;
}

.div-extracts-input input {
  position: relative;
  top: 5px;
}

.div-extracts-label {
  position: relative;
  top: 5px;
}

.date-picker-extract {
  text-align: left;
  align-self: self-start;
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 27px;
  line-height: 30px;
  color: #717171;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background: lightgray;
}
