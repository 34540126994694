@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.loader {
  border: 16px solid #29654f;
  /* Light grey */
  border-top: 16px solid #e3661f;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@import 'functions.scss';
@import 'variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'mixins.scss';
@import 'reboot.scss';
@import 'pages.scss';
@import 'components.scss';
@import 'app.scss';
@import 'helper.scss';

// * Color Admin v4.6.0 (https://seantheme.com/color-admin/)
//  @import 'functions';
//  @import 'variables';
//  @import 'node_modules/bootstrap/scss/bootstrap';
//  @import 'mixins';
//  @import 'reboot';
//  @import 'app';
//  @import 'layout';
//  @import 'ui';
//  @import 'plugins';
//  @import 'helper';

.inline-center {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.h3-gray-cod {
  @apply ml-2;
  @apply text-gray-cod;
  @apply text-3xl;
}

.h3-gray-cod-bold {
  @apply h3-gray-cod;
  @apply font-semibold;
}

.h3-gray-cod-thin {
  @apply h3-gray-cod;
  @apply font-thin;
}
