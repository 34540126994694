$header-size: 100px;
$sidebar-size: 327px;

.public {
  .content {
    @include display-flex();
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 327px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: $sidebar-size;
    margin-top: $header-size;
    overflow: hidden;
  }

  .public-button {
    border-style: none;
    border-radius: 50;
    height: 163px;
    width: 50%;
    font-size: xx-large;
    background: linear-gradient(180deg, rgba(19, 100, 22, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(267.16deg, rgba(203, 248, 107, 0.8) 34.2%, rgba(255, 255, 255, 0) 97.06%),
      rgba(13, 240, 22, 0.5);
  }
}
