.sidebar {
  @include display-flex();
  width: $sidebar-width;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: $header-height;
  background: $dark;
  z-index: $sidebar-zindex;
  max-width: 100%;
  height: auto;

  @media (max-height: 800px) {
    p {
      font-size: 10px;
    }
  }

  @include transform(translateZ(0));
  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    left: $sidebar-width * -1;
    z-index: $header-zindex + 1;

    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }

  & .nav {
    display: block;

    @if $enable-rtl {
      padding-right: 0;
    }

    & > li {
      position: relative;

      & .nav-logo {
        height: 25px;
        width: 25px;
        background-color: #dadada;
        border-radius: 50%;
        display: inline-block;
        margin-left: -5px;
        margin-right: 15px;
      }
      & .nav-perfil {
        margin-left: -50px;
        width: auto;
        padding: 40px;

        background: linear-gradient(
            0.04deg,
            rgba(254, 252, 252, 0.55) -220.07%,
            rgba(255, 255, 255, 0) 99.94%
          ),
          #3a3737;
      }

      @include clearfix();

      & > a {
        padding: rem(7px) rem(20px);
        line-height: rem(20px);
        color: rgba($white, 0.6);
        display: block;
        text-decoration: none;

        @include clearfix();

        & i {
          float: left;
          margin-right: rem(10px);
          width: rem(14px);
          text-align: center;
          line-height: rem(20px);
          font-size: rem(14px);

          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: rem(10px);
          }

          &[class^='ion-'] {
            margin-right: rem(8px);
            width: rem(18px);
            font-size: rem(18px);
            margin-left: rem(-2px);

            @if $enable-rtl {
              margin-right: rem(-2px);
              margin-left: rem(8px);
            }
          }
        }
        & .icon-img {
          float: left;
          margin-right: rem(10px);
          margin-top: rem(3px);
          width: rem(14px);
          height: rem(14px);
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include border-radius($border-radius);
          @include display-flex();
          @include flex-align(center);
          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: rem(10px);
          }

          & img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        & .badge {
          padding: rem(5px) rem(8px);
          background: $dark-darker;
          font-weight: 600;
          font-size: rem(10px);
        }
        & .label {
          padding-top: 0;
          padding-bottom: 0;
          margin-left: rem(8px);

          &.label-theme {
            @include gradient-enabled {
              @include gradient-linear(
                to bottom,
                (lighten($primary, 5%) 0%, darken($primary, 5%) 100%)
              );
            }
          }

          @if $enable-rtl {
            margin-left: 0;
            margin-right: rem(8px);
          }
        }
        & > span {
          @include display-flex();
          @include flex(1);
          @include flex-wrap(wrap);
        }
        &:hover,
        &:focus {
          background: none;
          color: $white;
        }
      }
      &.active {
        & > a {
          position: relative;
          z-index: 10;
          color: $white;
          background: darken($dark, 4%);

          @include gradient-enabled {
            @if ($primary-color == 'yellow') {
              color: $dark;
            }
            @include gradient-linear(
              to bottom,
              (lighten($primary, 5%) 0%, darken($primary, 5%) 100%)
            );
          }

          & i {
            color: $primary;

            @include gradient-enabled {
              color: rgba($black, 0.65);
            }
          }
          & .caret {
            @include gradient-enabled {
              color: rgba($black, 0.5);
            }
          }
          & .badge {
            background: rgba($black, 0.65);
          }
          & .label {
            &.label-theme {
              @include gradient-enabled {
                @if ($primary-color == 'yellow') {
                  color: $white;
                }
                background: rgba($black, 0.65);
              }
            }
          }
        }
      }
      &.nav-header {
        margin: 0;
        width: $sidebar-width;
        padding: rem(20px) rem(10px) rem(20px) rem(36px);
        line-height: rem(24px);
        font-size: rem(20px);
        font-style: normal;
        color: #d7e2de;
        font-weight: 600;
        letter-spacing: 0.15px;
        border-bottom: 0.2px solid #fbfbfb;
        background: linear-gradient(
            0.04deg,
            rgba(254, 252, 252, 0.55) -220.07%,
            rgba(255, 255, 255, 0) 99.94%
          ),
          #3a3737;

        & .nav-header-text {
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.15px;
          margin-left: 15px;

          color: #d7e2de;
        }

        & a {
          padding: 0;
          margin: 0;
          display: inline;
          cursor: pointer;

          &:hover,
          &:focus {
            background: none;
            color: $white;
          }
          & i {
            float: none;
            margin: 0;
          }
        }
      }
      &.nav-item {
        margin: 0;
        width: 80%;
        padding: rem(40px) rem(0px) rem(40px) rem(0px);
        line-height: rem(24px);
        font-size: rem(20px);
        font-style: normal;
        color: #d7e2de;
        font-weight: 600;
        letter-spacing: 0.15px;
        border-bottom: 0.2px solid #fbfbfb;
        height: 60px;
        margin-left: 36px;

        &.nav-item-active {
          color: white;
          font-size: rem(25px);
        }

        &:hover {
          color: white;
          font-size: rem(25px);
        }

        cursor: pointer;
      }
      &.nav-profile {
        padding: rem(20px);
        color: $white;
        background: $dark-darker;
        overflow: hidden;

        & a {
          padding: 0;
        }
        & > a {
          margin: rem(-20px);
          padding: rem(20px);
          display: block;
          color: $white;
          font-weight: 600;

          &:hover {
            & .cover {
              &.with-shadow {
                &:before {
                  opacity: 0.75;
                }
              }
            }
          }
        }
        & .image {
          width: rem(34px);
          height: rem(34px);
          margin-bottom: rem(10px);
          overflow: hidden;
          position: relative;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include display-flex();
          @include flex-align(center);
          @include border-radius($border-radius-lg * 5);

          & img {
            max-width: 100%;
            max-height: 100%;
          }
          &.image-icon {
            & i {
              float: none;
              display: block;
              font-size: rem(24px);
              text-align: center;
              width: auto;
              margin: 0 auto rem(-12px);
            }
          }
        }
        & .info {
          font-size: rem(14px);
          position: relative;

          & .caret {
            margin-top: rem(2px);
          }
          & small {
            display: block;
            color: rgba($white, 0.75);
            font-size: rem(12px);
            margin-bottom: rem(-5px);
          }
        }
        & .cover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          // background: url('images/cover-sidebar-user.jpg');
          background-repeat: no-repeat;
          background-size: cover;

          &.with-shadow {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: rgba($dark-darker, 0.75);

              @include transition(all 0.2s linear);
            }
          }
        }
      }
      &.nav-search {
        padding: rem(20px);

        & + li {
          padding-top: 0;
        }
        & .form-control {
          background: none;
          border: none;
          padding-left: 0;
          padding-right: 0;
          border-bottom: 1px solid rgba($white, 0.2);
          color: $white;

          @include border-radius(0);

          &:focus {
            border-color: rgba($white, 0.5);

            @include box-shadow(none);
          }
        }
      }
      &.nav-widget {
        padding: rem(10px) rem(20px);

        & i {
          margin-right: auto;
        }
      }
      & .text-theme {
        margin-left: rem(5px);

        @if $enable-rtl {
          margin-right: rem(5px);
          margin-left: 0;
        }
      }
    }
    & .has-sub {
      &.active {
        & > .sub-menu {
          display: block;
        }
      }
    }
    & .sub-menu {
      & > li {
        & > a {
          color: rgba($white, 0.45);

          & .caret {
            margin-top: rem(-1px);
            left: rem(-5px);
            color: rgba($white, 0.25);
          }
        }
      }
    }
    &.nav-profile {
      padding: rem(5px) 0;
      border-bottom: 1px solid $dark-darker;
      display: none;

      &.active {
        display: block;
      }
    }
  }
  & li {
    & > a {
      & .caret {
        display: block;
        float: right;
        width: rem(20px);
        height: rem(20px);
        line-height: rem(20px);
        text-align: center;
        font-size: rem(11px);
        border: none;

        @include fontawesome();
        @if $enable-rtl {
          float: left;
        }

        &:before {
          content: '\f054';
          display: block;
          text-align: center;
          opacity: 0.5;

          @include transition(all 0.2s linear);
          @if $enable-rtl {
            content: '\f053';
          }
        }
      }
    }

    &.active,
    &.expanding,
    &.expand {
      & > a {
        & .caret {
          &:before {
            opacity: 1;

            @include transform(rotate(90deg));
            @if $enable-rtl {
              @include transform(rotate(-90deg));
            }
          }
        }
      }
    }
    &.closed,
    &.closing {
      & > a {
        & .caret {
          &:before {
            @include transform(rotate(0deg));
          }
        }
      }
    }
  }
  & .sub-menu {
    list-style-type: none;
    padding: rem(5px) 0 rem(10px) rem(39px);
    margin: 0;
    position: relative;
    display: none;

    @if $enable-rtl {
      padding-left: 0;
      padding-right: rem(39px);
    }

    & > li {
      position: relative;

      & > a {
        padding: rem(3px) rem(20px) rem(3px) rem(15px);
        display: block;
        color: rgba($white, 0.6);
        text-decoration: none;
        position: relative;

        @if $enable-rtl {
          padding-right: rem(15px);
          padding-left: rem(20px);
        }

        &:hover,
        &:focus {
          color: $white;
        }
        &:after {
          content: '';
          position: absolute;
          left: rem(-11px);
          top: rem(11px);
          width: rem(11px);
          height: rem(2px);
          background: darken($dark, 4%);

          @if $enable-rtl {
            right: rem(-11px);
            left: auto;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: rem(-13px);
        top: 0;
        bottom: 0;
        width: rem(2px);
        background: darken($dark, 4%);

        @if $enable-rtl {
          right: rem(-13px);
          left: auto;
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: rem(6px);
        height: rem(6px);
        border: 1px solid rgba($white, 0.6);
        top: rem(11px);
        margin-top: rem(-2px);
        z-index: 10;
        background: $dark;

        @include border-radius(4px);
        @if $enable-rtl {
          left: auto;
          right: 0;
        }
      }
      &:first-child {
        &:before {
          top: rem(-14px);
        }
        &:last-child:before {
          height: rem(27px);
        }
      }
      &:last-child {
        &:before {
          bottom: auto;
          height: rem(13px);
        }
      }
      &.has-sub {
        & > a {
          &:before {
            background: darken($dark, 4%);
          }
        }
      }
      &.active {
        & > a {
          color: $white;
        }
        &:after {
          border-color: $primary;
        }
      }
    }
    & .sub-menu {
      padding: 0 0 0 rem(15px);
      background: none;

      @if $enable-rtl {
        padding-left: 0;
        padding-right: rem(15px);
      }
    }
  }
  & .sidebar-minify-btn {
    margin: rem(10px) 0;
    float: right;
    padding: rem(5px) rem(20px) rem(5px) rem(10px) !important;
    background: darken($dark, 4%);
    color: $white;

    @include border-radius($border-radius * 5 0 0 $border-radius * 5);
    @if $enable-rtl {
      float: left;

      @include border-radius(0 $border-radius * 5 $border-radius * 5 0);
    }

    & i {
      margin: 0 !important;
      color: $white;
    }
    &:hover,
    &:focus {
      background: darken($dark, 8%) !important;
    }
  }
  & .slimScrollDiv {
    & .slimScrollBar {
      &:before {
        background: rgba($white, 0.5) !important;
      }
    }
  }
  &.sidebar-grid {
    & .nav {
      & > li {
        & + li {
          margin-top: -1px;
        }
        & > a {
          border-top: 1px solid lighten($dark, 7.5%);
          border-bottom: 1px solid lighten($dark, 7.5%);
        }

        &.expanding,
        &.expand {
          & > a {
            border-bottom-color: transparent;
          }
        }
        &.active {
          & > a {
            border-color: $dark;
          }
        }
      }
    }
  }
  &.sidebar-transparent {
    background: none;

    & + .sidebar-bg {
      // background: url(images/cover-sidebar.jpg);
      background-size: cover;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($dark, 0.75);
      }
    }
    & .nav {
      & > li {
        &.active {
          & > a {
            background: rgba($black, 0.3);
          }
        }
        &.nav-profile {
          background: rgba($black, 0.25);

          & a {
            &:hover,
            &:focus {
              background: none;
            }
          }
          & .cover {
            background: none;

            &.with-shadow {
              &:before {
                background: none;
              }
            }
          }
          &.active {
            background: rgba($black, 0.25);
          }
        }
      }
    }
    & .sub-menu {
      background: none;

      & > li {
        &:before {
          background: rgba($black, 0.45);
        }
        & > a {
          &:after {
            background: rgba($black, 0.45);
          }
        }
        &.has-sub {
          & > a {
            &:after {
              background: rgba($black, 0.45);
            }
          }
        }
      }
    }
  }
}
.sidebar-bg {
  background: $dark;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  z-index: $sidebar-zindex - 10;

  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    left: $sidebar-width * -1;
    z-index: $header-zindex;

    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }
}
