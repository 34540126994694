@mixin background-gradient($className, $from, $to) {
  .#{$className},
  .#{$className}-to-right,
  .#{$className}-diagonal-down,
  .#{$className}-diagonal-up {
    background: $from;
  }
  .#{$className} {
    background: -moz-linear-gradient(bottom, $from 0%, $to 100%);
    background: -webkit-linear-gradient(bottom, $from 0%, $to 100%);
    background: linear-gradient(to bottom, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
  }
  .#{$className}-to-right {
    background: -moz-linear-gradient(right, $from 0%, $to 100%);
    background: -webkit-linear-gradient(right, $from 0%, $to 100%);
    background: linear-gradient(to right, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
  .#{$className}-to-top {
    background: -moz-linear-gradient(top, $from 0%, $to 100%);
    background: -webkit-linear-gradient(top, $from 0%, $to 100%);
    background: linear-gradient(to top, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
  .#{$className}-to-left {
    background: -moz-linear-gradient(left, $from 0%, $to 100%);
    background: -webkit-linear-gradient(left, $from 0%, $to 100%);
    background: linear-gradient(to left, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
  .#{$className}-diagonal-down {
    background: -moz-linear-gradient(-45deg, $from 0%, $to 100%);
    background: -webkit-linear-gradient(-45deg, $from 0%, $to 100%);
    background: linear-gradient(-45deg, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
  .#{$className}-diagonal-up {
    background: -moz-linear-gradient(45deg, $from 0%, $to 100%);
    background: -webkit-linear-gradient(45deg, $from 0%, $to 100%);
    background: linear-gradient(45deg, $from 0%, $to 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
  }
}
