.box-container {
  display: flex;
  justify-content: space-between;
  border-left: 5px solid #dbd4d4;
  padding-bottom: 40px;
}

.date-picker {
  text-align: left;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 27px;
  line-height: 30px;
  color: #717171;
}

.date-txt {
  font-weight: 500;
  text-align: right;
  color: #717171;
  font-size: 17px;
}

.time-txt {
  font-weight: 500;
  text-align: right;
  color: #717171;
  font-size: 17px;
}

.ref-txt {
  font-weight: 500;
  text-align: left;
  color: #717171;
  font-size: 17px;
}

.box-1 {
  padding-left: 3%;
  width: 100%;
  height: 100%;
  text-align: left;
}

.box-2 {
  width: 100%;
  height: 100%;
  text-align: right;
}

.dot {
  height: 18px;
  width: 18px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: -27px;
  margin-right: 8px;
}
.container {
  padding: 50px;
  text-align: center;
  border-radius: 15;
  background: white;
  color: black;
  height: 100%;
  width: 80%;
  font-size: xx-large;
  margin-top: 100px;
  margin-bottom: 30px;
}
