.button {
  border-radius: 50px;
  height: 63px;
  min-width: 346px;
  font-size: large;
  background: linear-gradient(180deg, rgba(19, 100, 22, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(267.16deg, rgba(203, 248, 107, 0.8) 34.2%, rgba(255, 255, 255, 0) 97.06%),
    rgba(13, 240, 22, 0.5);
  border-style: none;
}

.value-display {
  color: #0aef13;
  margin-bottom: 50px;
}

.div-background {
  text-align: center;
  border-radius: 15px;
  background: white;
  color: black;
  font-size: x-large;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 70px;
  max-width: 100%;
  max-height: 100%;
}

.div-background-extract {
  text-align: center;
  border-radius: 15px;
  background: white;
  color: black;
  font-size: x-large;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 70px;
  max-width: 100%;
  max-height: 100%;
}

.main-text {
  margin-top: 70px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 70px;
}

.cancel-button {
  color: white;
  margin-top: 25px;
  border-style: none;
  border-radius: 50px;
  height: 63px;
  min-width: 346px;
  font-size: large;
  background: linear-gradient(180deg, rgba(200, 35, 35, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(266.95deg, rgba(234, 32, 32, 0.8) 34.21%, rgba(255, 255, 255, 0) 160.86%),
    rgba(240, 13, 13, 0.5);

  &:hover {
    background: rgba(234, 32, 32, 0.8);
  }
}

.qr-content-div {
  padding: 50px;
  text-align: center;
  border-radius: 15px;
  background: white;
  color: black;
  height: 743px;
  width: 714px;
  display: center;
  font-size: xx-large;

  & p {
    margin-top: 53px;
  }
}

.confirmed-text {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: #0aef13;
}

.check-icon {
  position: relative;
  bottom: 205px;
}

.qr-code-icon-confirmed {
  width: 230px;
  height: 230px;
}

.goback-btn {
  display: inline-grid;
  margin-top: 15px;
  border-radius: 50px;
  height: 41px;
  min-width: 235px;
  font-size: large;
  background: linear-gradient(180deg, rgba(200, 35, 35, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(266.95deg, rgba(234, 32, 32, 0.8) 34.21%, rgba(255, 255, 255, 0) 160.86%),
    rgba(240, 13, 13, 0.5);
  border-style: none;
}

.goback-btn-hidden {
  display: none;
}

.linear {
  background: linear-gradient(180deg, rgba(19, 100, 22, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(267.16deg, rgba(203, 248, 107, 0.8) 34.2%, rgba(255, 255, 255, 0) 97.06%),
    rgba(13, 240, 22, 0.5);
}
