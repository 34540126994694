@mixin helper-row-space($from, $to) {
  @for $i from $from through $to {
    $classInt: $i * 2;
    $classValue: $classInt / 2;

    .row.row-space-#{$classInt} {
      margin-left: rem($classValue * -1);
      margin-right: rem($classValue * -1);
    }
    .row.row-space-#{$classInt} > [class*='col-'] {
      padding-left: rem($classValue);
      padding-right: rem($classValue);
    }
  }
}
