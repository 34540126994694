.index-btn {
  border-style: none;
  border-radius: 50px;
  height: 100px;
  width: 360px;
  font-size: xx-large;
  background: linear-gradient(180deg, rgba(19, 100, 22, 0.8) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(267.16deg, rgba(203, 248, 107, 0.8) 34.2%, rgba(255, 255, 255, 0) 97.06%),
    rgba(13, 240, 22, 0.5);

  &:first-child {
    margin-bottom: 50px;
  }
}
